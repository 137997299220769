import classNames from 'classnames'
import type { FunctionComponent } from 'react'

import styles from './badge.module.scss'

/** Badge with some content */
export const Badge: FunctionComponent<React.PropsWithChildren<Props>> = ({
    className,
    children,
    size = 'medium',
    ...restOfProps
}) => (
    <div
        className={classNames(styles.badge, styles[size], className)}
        {...restOfProps}
    >
        {children}
    </div>
)

interface Props {
    /**
     * Extra class name for positioning and stuff.
     */
    className?: string

    /**
     * Affects width, height, padding, font-size, border, etc.
     * @default 'medium'
     */
    size?: 'medium'
}
