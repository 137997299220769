import type { FunctionComponent, ReactNode } from 'react'
import { isValidElement } from 'react'

import { Image } from '../../components/image'
import { Skeleton } from '../../components/skeleton'
import type { ImageType } from '../../types/image'
import styles from './article-cover.module.scss'

/**
 * Article cover image or any JSX element.
 */
export const ArticleCover: FunctionComponent<ArticleCoverProps> = ({
    element,
    loading,
    image,
}) => {
    if (loading) {
        return <Skeleton className={styles.skeleton} width='100%' />
    }

    if (isValidElement(element)) {
        return <div className={styles.container}>{element}</div>
    }

    if (!image) return null

    return (
        <div className={styles.container}>
            <Image
                width='100%'
                fit='contain'
                className={styles.image}
                lazy={false}
                source={image.source}
                source2x={image.source2x}
                source3x={image.source3x}
                contentType={image.contentType}
                alt={image.alt}
            />
        </div>
    )
}

export type ArticleCoverProps = {
    element?: ReactNode
    image?: ImageType
    loading?: boolean
}
